<template>
  <net-cloud-sec />
</template>

<script>
import NetCloudSec from "../../components/solutions/security-solutions/NetCloudSec.vue";
export default {
  components: { NetCloudSec },
  name: "network-cloud-page",
};
</script>
